// footer
.footer {

    @include graas();

    a {
        text-decoration: none !important;
    }

    .footer-outro {
        background-color: $white;
        padding: 6rem 0 2rem;

        .container-three-columns {
            .container-holder {
                position: relative;
                background-color: $brown;
                border-radius: 4px;
                margin: 0;
                padding: 2rem 0 4rem;

                &::before {
                    content: '';
                    display: block;
                    width: 90px;
                    height: 120px;
                    background-image: url('/images/sandals.svg');
                    background-size: contain;
                    background-repeat: no-repeat;
                    position: absolute;
                    left: 58%;
                    top: -60px;
                }

                &::after {
                    content: '';
                    display: block;
                    width: 57px;
                    height: 53px;
                    background-image: url('/images/sea-star.svg');
                    background-size: contain;
                    background-repeat: no-repeat;
                    position: absolute;
                    left: 15%;
                    bottom: -26px;
                    
                    @include media-breakpoint-up(lg) {
                        left: 35%;
                    }
                }
            }
        }

        .container-holder {
            .column {
                justify-content: center;
                align-items: center;

                &.one {
                    .btn {
                        padding: 1.25rem 2rem;
                    }
                }

                @include media-breakpoint-down(md) {
                    @include make-col(12);

                    &.two {
                        order: -1;
                    }
                }

                @include media-breakpoint-up(lg) {
                    margin-bottom: 0
                }
            }
        }

        h1, h2, h3, h4, h5, h6 {
            color: $white;
        }

        .wysiwyg {
            p {
                &:last-child {
                    @include media-breakpoint-up(lg) {
                        margin-bottom: 0;
                    }
                }
            }
        }

        div.social-media {
            ul.social-media {
                @extend .custom-icon-list;

                a {
                    color: $white !important;
                    font-size: 30px !important;
                    width: 32px !important;
                    height: 32px !important;
                    
                    &:hover {
                        color: $brown-dark !important;
                    }
                }
            }
        }

        .footer-logolink {
            .list {
                gap: 2.5rem;
                align-items: center;
            }
        }
    }

    .footer-sitelinks {
        position: relative;

        .container {
            position: relative;

            &::before {
                content: '';
                display: block;
                width: 100px;
                height: 78px;
                background-image: url('/images/fish-right.svg');
                background-repeat: no-repeat;
                background-size: contain;
                position: absolute;
                right: 1.25rem;
                top: -65px;

                @include media-breakpoint-up(md) {
                    width: 168px;
                    height: 130px;
                    top: -70px;
                    right: 3rem;
                }
            }

            &::after {
                content: '';
                display: block;
                width: 130px;
                height: 78px;
                background-image: url('/images/fish-left.svg');
                background-repeat: no-repeat;
                background-size: contain;
                position: absolute;
                left: 1.25rem;
                top: -60px;

                @include media-breakpoint-up(md) {
                    width: 197px;
                    height: 119px;
                    top: -70px;
                    left: 3rem;
                }
            }
        }

        &::before {
            content: '';
            display: block;
            width: 100%;
            height: 24px;
            background-image: url('/images/circels.svg');
            background-repeat: repeat-x;
            background-size: contain;
            position: absolute;
            top: -12px;
        }

        .logo {
            margin: 1rem;
        }
    }

    .footer-copyright {
        padding-top: 0;

        .footer-link {
            .list {
                justify-content: center;
                flex-direction: column;
            } 
        }
    }
}
