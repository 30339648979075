// mini-sab
.mini-sab {
	.container {
		position: relative;
		z-index: 3;
		@extend .d-flex;
		@extend .justify-content-center;

		.container-holder {
			z-index: 100;
			position: absolute;
			left: 40px;
			right: 40px;
			bottom: 40px;

			// above eyecatcher
			@include media-breakpoint-up(lg) {
				bottom: 85px;
				left: auto;
				right: auto;
			}
		}
	}
}
