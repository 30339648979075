// container
.container {
	@media (min-width: 1280px) {
		max-width: 1210px;
	}

	@include media-breakpoint-down(sm) {
		padding-left: 40px;
		padding-right: 40px;
	}

	&.container-small {
		@include media-breakpoint-up(sm) {
			max-width: 540px;
		}
	}

	&.container-medium {
		@include media-breakpoint-up(md) {
			max-width: 720px;
		}
	}

	&.container-large {
		@include media-breakpoint-up(lg) {
			max-width: 960px;
		}
	}

	&.container-intro {
		@include media-breakpoint-up(md) {
			max-width: 720px;
		}

		margin-bottom: 30px;
		text-align: center;
	}

	&.container-outro {
		@include media-breakpoint-up(md) {
			max-width: 720px;
		}

		margin-top: 30px;
		text-align: center;
	}
}

// paragraph
p {
	min-height: 1rem;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.3s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// owl-carousel navs
.owl-carousel {
	.owl-nav {
		.owl-prev,
		.owl-next {
			font-size: 1.5rem;
		}
	}
} 

// default-card
.default-card {
	border: 6px solid $white;
	border-radius: 4px;
}

&.home {
	.card:not(.icon) {
		.card-caption {
			.card-title {
				margin-bottom: 0;
				font-size: $h4-font-size;
			}

			.card-subtitle,
			.card-description {
				display: none;
			}
		}
	}
}