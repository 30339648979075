&.accommodation-search-book {
    .lead-section {
        @extend .bg-white;
    }
}

&.accommodation-category-detail {
    .lead-section {
        @extend .bg-white;

        .container {
            @media (min-width: 1280px) {
                max-width: 1210px;
            }
        }
    }
}

&.accommodation-detail {
    .lead-section + .content-section::before {
        display: none;
    }

    .lead-section {
        .btn-back {
            margin-bottom: 0 !important;
        }
    }
}

&.accommodation-book-terms-and-conditions {
    .lead-section {
        @extend .bg-white;

        .container {
            @media (min-width: 1280px) {
                max-width: 1210px;
            }
        }
    }
}