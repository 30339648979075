&.disclaimer,
&.one-column-page,
&.two-column-page,
&.three-column-page,
&.landing-page {
    .lead-section {
        @extend .bg-white;

        .container {
            @media (min-width: 1280px) {
                max-width: 1210px;
            }
        }
    }
}

&.landing-page {
    section {
        &.bg-white {
            @include graas($white, right);
        }
    }

    .content-section {
        &:not(.bg-white) {
            @include graas($brown-light);
        }
    }
}