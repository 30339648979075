.owl-carousel {
    .owl-dots {
        justify-content: flex-start;
        padding-left: 40px;

        .owl-dot {
            span {
                width: 30px;
                height: 4px;
                box-shadow: none;
                border: none;
                background-color: $white;
            }

            &.active {
                span {
                    background-color: $primary;
                }
            }
        }
    }
}