// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 60vh;
	min-height: 300px;
	max-height: 540px;

	.owl-caption {
		.owl-title {
			font-size: 40px;
			
			@include media-breakpoint-up(lg) {
				font-size: 64px;
			}
		}
	}
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 85vh;
	min-height: 300px;
	max-height: 500px;

	@include media-breakpoint-up(sm) {
		height: 100vh;
		max-height: 680px;
	}
}

// eyecatcher
.eyecatcher {
	position: relative;
	overflow: hidden;

	// vimeo-video
	.vimeo-video {
		iframe {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 100vw;
			height: 100vh;
			max-width: none;
			transform: translate(-50%, -50%);

			@media (min-aspect-ratio: 16/9) {
				height: 56.25vw;
			}

			@media (max-aspect-ratio: 16/9) {
				width: 177.78vh;
			}
		}
	}
}
