@mixin graas($bg: #FFF, $pos: left) {
    position: relative;

    &::before {
        content: '';
        background-color: $bg;
        position: absolute;
        width: 295px;
        height: 144px;
        display: block;
        left: 0;
        bottom: calc(100% - 3rem);
        mask-image: url('/images/graas.svg');
        mask-position: bottom left;
        mask-repeat: no-repeat;
        z-index: 1;

        @if ($pos == right) {
            left: auto;
            right: 0;
            transform: scaleX(-1);
        }

        @include media-breakpoint-up(lg) {
            width: 413px;
            height: 202px;
            bottom: calc(100% - 4rem);
        }
    }
}