// custom-list
.custom-list {
	padding: 0;
	list-style: none;

	li {
		position: relative;
		padding: 0 0 0 1.5rem;

		&::before {
			content: "\f00c";
			position: absolute;
			top: 0;
			left: 0;
			font-family: $font-awesome;
			color: $primary;
			font-weight: 900;
		}
	}
}

// custom-list-extra
.custom-list-extra {
	padding: 0;
	list-style: none;

	li {
		position: relative;
		padding: 0 0 0 1.5rem;

		&::before {
			content: "\f058";
			position: absolute;
			top: 0;
			left: 0;
			font-family: $font-awesome;
			color: $primary;
			font-weight: 400;
		}
	}
}

// vocabulary-term-list: icon + title
.custom-icon-list {
	flex-wrap: nowrap !important;
	gap: 1rem;
	justify-content: flex-end;

	a {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 20px;
		height: 20px;
		text-decoration: none !important;
		font-size: 18px;
		color: $brown;

		&:hover {
			color: $brown-dark;

			.list-item-title {
				opacity: 1;
				visibility: visible;
				bottom: 100%;
			}
		}

		.list-item-title {
			position: absolute;
			background-color: $black;
			color: $white;
			font-size: 0.75rem;
			left: 50%;
			bottom: 10%;
			padding: 4px 10px;
			border-radius: 4px;
			margin-bottom: 0.5rem;
			transform: translateX(-50%);
			opacity: 0;
			visibility: hidden;
			transition: 0.3s;
			pointer-events: none;

			&::after {
				content: "";
				position: absolute;
				width: 10px;
				height: 10px;
				background-color: $black;
				top: 100%;
				left: 50%;
				transform: translateX(-50%) translateY(-5px) rotateZ(45deg);
			}
		}
	}
}
