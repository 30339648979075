.collection:not(.collection-icons) {
    &.grid {
        .grid-items {
            .item {
                @include media-breakpoint-up(sm) {
                    @include make-col(12);
                }

                @include media-breakpoint-up(md) {
                    @include make-col(6);
                }

                .card {
                    @extend .default-card;
                }
            }
        }
    }
}

.collection-icons {
    margin-top: 2rem;

    &.grid {
        .grid-items {
            .item {
                @include media-breakpoint-up(sm) {
                    @include make-col(12);
                }
    
                @include media-breakpoint-up(md) {
                    @include make-col(4);
                }
    
                .card.icon {
                    flex-direction: row;
                    align-items: center;
                    padding: 18px 20px;
                    border: none;
                    border-radius: 4px;
                    transition: .3s;

                    &:hover {
                        background-color: $primary;

                        * {
                            color: $white !important;
                            border-color: $white !important;
                        }
                    }
    
                    .card-image {
                        height: 100%;
                        font-size: 30px;
                        padding-right: 18px;
                        margin-right: 18px;
                        border-right: 1px solid $black;
                        display: flex;
                        align-items: center;
                        transition: border .3s;

                        i {
                            transition: .3s;
                        }
                    }
    
                    .card-body {
                        padding: 0;
                    }
    
                    .card-buttons {
                        display: none;
                    }
    
                    .card-caption {
                        .card-title {
                            color: $primary;
                            font-size: 1rem;
                            margin-bottom: 0;
                        }
    
                        .card-description {
                            font-size: 13px;
                            p {
                                margin-bottom: 0;
                                transition: .3s;
                            }
                        }
                    }
                }
            }
        }
    }
}

.collection-overlay {
    &.grid {
        .card-overlay {
            border: 0 !important;
            
            .card-img-overlay {
                background: linear-gradient(226deg, rgba($black, 0) 35%, rgba($black, .6) 100%);
                display: flex;
                align-items: flex-end;
            }

            .card-caption {
                .card-title {
                    font-size: 2rem;
                    max-width: 300px;
                }
            }
        }
    } 
}