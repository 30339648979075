// header
.header {
	z-index: 998;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	background: $brown-light;
	transition: 0.5s;

	&.sticky {
		box-shadow: $shadow;
	}

	.container,
	.container-fluid {

		@include media-breakpoint-up(lg) {
			padding: 0 40px;
		}

		.container-holder {
			>.column {
				align-items: center;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// news-ticker
	.news-container {
		background: $white;
		position: relative;
		overflow: hidden;
		width: 100% !important;

		@include media-breakpoint-down(lg) {
			.column {
				padding: 0;
			}
		}

		@include media-breakpoint-down(md) {
			margin-bottom: .875rem;
		}

		div.news-ticker {
			display: flex;
			flex-flow: row nowrap;
			margin: auto;

			&:hover ul {
				animation-play-state: paused;
			}

			ul {
				height: 40px;
				animation: scroll 25s linear infinite;
				transition: 1s ease-out;
				display: flex !important;
				flex-flow: row nowrap !important;
				align-items: center;
				justify-content: center;
				gap: 3rem;
				width: max-content;
				padding-left: 50%;
				
				@include media-breakpoint-up(lg) {
					padding-left: 0;
					animation: none;
				}

				a {
					text-decoration: none;

					&:hover {
						text-decoration: underline !important;
						text-underline-offset: 7px;
					}
				}

				li {
					min-width: fit-content;
					display: flex;
					align-items: center;
					font-size: 13px;
					font-weight: 400;
					color: $blue-dark;

					b {
						font-weight: 700;
					}

					&::before {
						content: "\f2f3";
						font-family: $font-awesome;
						color: $brown;
						font-weight: 900;
						margin-right: .5rem;
					}
				}

				@keyframes scroll {
					0% {
						transform: translateX(0%);
					}

					100% {
						transform: translateX(-50%);
					}
				}
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		@include media-breakpoint-down(md) {
			order: 1;
			margin-left: auto;
		}

		.navbar-toggler {
			width: 40px;
			height: 40px;
			padding: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: $white;
			border-radius: 50%;
			color: $brown-dark;

			&[aria-expanded=false] {
				.navbar-toggler-icon {
					.fa-times {
						display: none;
					}
				}
			}

			&[aria-expanded=true] {
				.navbar-toggler-icon {
					.fa-bars {
						display: none;
					}
				}
			}
		}
	}

	// logo
	.logo {
		// width: 75px;
		margin: 2rem 0px -26px 0px;

		@include media-breakpoint-down(md) {
			margin-bottom: -1rem;
			margin-top: 0;
			width: 290px;
			order: 1;
		}

		a {
			@extend .d-block;

			img {
				@extend .w-100;
			}
		}
	}

	// menu
	.menu {
		// global menu styles
		.navbar-nav {
			.nav-item {
				.nav-link {
					padding-left: 1rem !important;
					padding-right: 1rem !important;
					color: $brown-dark;
					font-weight: 700;
				}

				&.active,
				&:hover {
					> .nav-link {
						color: $blue-dark;
					}
				}
			}
		}

		@include media-breakpoint-down(lg) {
			order: 10;

			.navbar-nav {
				margin-top: 2rem;
			}
		}

		@include media-breakpoint-up(xl) {
			justify-content: flex-end;
			margin-right: 1.5rem;
		}
	}

	// header-icons
	div.header-icons {
		margin-left: auto;

		@include media-breakpoint-down(md) {
			flex-basis: 65%;
		}


		ul.header-icons {
			@extend .custom-icon-list;
		}
	}

	// site-switcher
	.site-switcher {
		margin-left: 1rem;
		
		@include media-breakpoint-down(lg) {
			margin-right: 1rem;
		}

		.navbar-nav {
			&.language {
				.nav-item {
					.nav-link {
						box-shadow: none !important;
						border: 0 !important;
						width: 20px;
						height: 20px;
						display: flex;
						justify-content: center;
						align-items: center;

						&:hover {
							.lang-name {
								opacity: 1;
								visibility: visible;
								bottom: 100%;
							}
						}

						i {
							font-size: 18px;
							color: $brown;
							transition: .3s;

							&:hover {
								color: $brown-dark;
							}
						}

						.lang-name {
							position: absolute;
							background-color: $black;
							color: $white;
							font-size: .75rem;
							left: 50%;
							bottom: 10%;
							padding: 4px 10px;
							border-radius: 4px;
							margin-bottom: .5rem;
							transform: translateX(-50%);
							opacity: 0;
							visibility: hidden;
							transition: .3s;
							pointer-events: none;
		
							&::after {
								content: "";
								position: absolute;
								width: 10px;
								height: 10px;
								background-color: $black;
								top: 100%;
								left: 50%;
								transform: translateX(-50%) translateY(-5px) rotateZ(45deg);
							}
						}

						&::after {
							display: none;
						}
					}
				}

				.dropdown-menu {
					overflow: hidden;
					border-color: $brown;

					.dropdown-item {
						color: $brown-dark;
						font-size: 14px;
						font-weight: 700;

						&:hover {
							background-color: $brown-light;
							color: $blue-dark;
						}
					}
				}
			}
		}
	}
}
